.cursor {
  width: 1rem;
  height: 1rem;
  background: var(--primary);
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 0.1s ease;
  /* transition-property: background, transform; */
  transform-origin: 50% 50%;
  z-index: 10;
}
